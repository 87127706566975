//max 54
let crmItems = [{
        id: "01",
        icon: "#icon-dashboard",
        index: "dashboard",
        title: "工作台",
        member: true,
    },
    {
        id: "02",
        icon: "#icon-team",
        index: "customer",
        title: "客户管理",
        member: true,
        type: "customer",
        subs: [{
                id: "020",
                index: "customerInput",
                title: "客户录入",
                memberRoot: true,
            },
            {
                id: "021",
                index: "customerManagement",
                title: "客户管理",
                memberRoot: true,
            },
            {
                id: "022",
                index: "totalCustomer",
                title: "总客户池",
                memberRoot: true,
            },
            {
                id: "023",
                index: "returnVisit",
                memberRoot: true,
                title: "待回访区",
            },
            {
                id: "024",
                index: "followUpAnalyse",
                memberRoot: true,
                title: "客户跟进分析",
            },
        ],
    },
    {
        icon: "el-icon-s-finance",
        index: "walletRecords",
        title: "客户钱包",
        member: true,
        id: "53",
        subs: [{
                id: "530",
                index: "walletRecords",
                title: "客户钱包流水",
                memberRoot: true,
            },
            {
                id: "531",
                index: "integralRecord",
                title: "积分操作记录",
                memberRoot: true,
            },
        ]
    },
    {
        id: "46",
        icon: "#icon-team",
        index: "partnership",
        title: "互通管理",
        member: true,
        subs: [{
                id: "460",
                index: "partnership",
                title: "合作伙伴",
                memberRoot: true,
            },
            {
                id: "461",
                index: "companyGoodsLink",
                title: "商品互通",
                memberRoot: true,
            },
            {
                id: "463",
                index: "companyOrderGoodsLink",
                title: "预约商品",
                memberRoot: true,
            },
            {
                id: "462",
                index: "sharedLibrary",
                title: "商品共享库",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "#icon-bank",
        index: "03",
        title: "权限管理",
        member: true,
        id: "03",
        subs: [{
                id: "030",
                index: "corporateInformation",
                title: "企业信息",
                memberRoot: true,
            },
            {
                id: "031",
                index: "enterpriseMember",
                title: "企业成员",
                memberRoot: true,
                hideenSubs: true,
                subs: [{
                        id: "0310",
                        title: "部门设置",
                        subs: [{
                                id: "0311",
                                title: "仅可设置自己负责的部门",
                            },
                            {
                                id: "0312",
                                title: "可设置所有部门",
                            },
                        ]
                    },
                    {
                        title: '管理员设置',
                        id: '0311',
                    },
                    {
                        title: '成员设置',
                        id: '0312',
                    },
                    {
                        title: '设置员工目标',
                        id: '0313',
                    },
                ],
            },
            {
                id: "032",
                index: "performanceAssessment",
                memberRoot: true,
                title: "成员考核",
            },
            {
                id: "033",
                index: "advancedForm",
                title: "高级表单",
                memberRoot: true,
            },

            {
                id: "034",
                index: "advertisingManagement",
                title: "广告图管理",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "el-icon-collection",
        index: "projectAdmin",
        title: "项目管理",
        id: "49",
        member: true,
        subs: [{
                index: "projectCompany",
                title: "研发企业",
                memberRoot: true,
                id: "492",
            },
            {
                index: "projectDepartment",
                title: "研发部门",
                memberRoot: true,
                id: "495",
            },
            {
                index: "projectUser",
                title: "项目人员",
                memberRoot: true,
                id: "491",
                subs: [{
                        index: "projectUser",
                        title: "项目人员",
                        memberRoot: true,
                        id: "4910",
                    },
                    {
                        index: "allocationChart",
                        title: "人员分配图",
                        memberRoot: true,
                        id: "4911",
                    }
                ]
            },
            {
                index: "projectAdmin",
                title: "项目管理",
                memberRoot: true,
                id: "490",
            },
            {
                index: "projectProperty",
                title: "知识产权",
                memberRoot: true,
                id: "496",
            },
            {
                title: "项目模板",
                index: "projectModel",
                member: true,
                id: "495",
                subs: [{
                        index: "modelAdmin",
                        title: "模板管理",
                        memberRoot: true,
                        id: "492",
                    },
                    {
                        index: "documentAdmin",
                        title: "Word管理",
                        memberRoot: true,
                        id: "493",
                    },
                    {
                        index: "pdfAdmin",
                        title: "PDF管理",
                        memberRoot: true,
                        id: "494",
                    },
                ]
            },
            {
                icon: "el-icon-postcard",
                index: "expenseManagement",
                title: "研发费管理",
                id: "50",
                member: true,
                subs: [{
                        index: "costEnter",
                        title: "研发费导入录入",
                        memberRoot: true,
                        id: "502",
                    },
                    {
                        index: "assistrecord",
                        title: "研发支出辅助账",
                        memberRoot: true,
                        id: "505",
                    },
                    {
                        index: "researchCosts",
                        title: "研发费归集表",
                        memberRoot: true,
                        id: "506",
                    },
                    {
                        index: "personnelAllotment",
                        title: "研发费分配说明",
                        memberRoot: true,
                        id: "507",
                    },
                    {
                        index: "report",
                        title: "辅助账报表生成",
                        memberRoot: true,
                        id: "500",
                        disabled: true,
                    },
                    {
                        index: "costAdjustment",
                        title: "研发费用调整项录入",
                        memberRoot: true,
                        id: "501",
                        disabled: true,
                    },
                    {
                        index: "voucherInfo",
                        title: "凭证信息录入",
                        memberRoot: true,
                        id: "503",
                        disabled: true,
                    },
                    {
                        index: "startMoney",
                        title: "期初余额设置",
                        memberRoot: true,
                        id: "504",
                        disabled: true,
                    },
                ],
            },
            {
                icon: "el-icon-postcard",
                index: "initialRecognition",
                title: "制度-初次认定",
                id: "51",
                member: true,
                subs: [{
                        index: "organizationalManagement911",
                        title: "管理制度9.1",
                        memberRoot: true,
                        id: "510",
                    },
                    {
                        index: "organizationalManagement912",
                        title: "管理制度9.2",
                        memberRoot: true,
                        id: "511",
                    },
                    {
                        index: "organizationalManagement913",
                        title: "管理制度9.3",
                        memberRoot: true,
                        id: "512",
                    },
                    {
                        index: "organizationalManagement914",
                        title: "管理制度9.4",
                        memberRoot: true,
                        id: "513",
                    },

                ],
            },
            {
                icon: "el-icon-postcard",
                index: "reIdentification",
                title: "制度-重新认定",
                id: "52",
                member: true,
                subs: [{
                        index: "organizationalManagement921",
                        title: "管理制度9.1",
                        memberRoot: true,
                        id: "520",
                    },
                    {
                        index: "organizationalManagement922",
                        title: "管理制度9.2",
                        memberRoot: true,
                        id: "521",
                    },
                    {
                        index: "organizationalManagement923",
                        title: "管理制度9.3",
                        memberRoot: true,
                        id: "522",
                    },
                    {
                        index: "organizationalManagement924",
                        title: "管理制度9.4",
                        memberRoot: true,
                        id: "523",
                    },

                ],
            },
        ],
    },
    {
        icon: "#icon-file-text",
        index: "orderManagement",
        title: "订单管理",
        member: true,
        id: "04",
    },
    {
        icon: "#icon-shopping",
        index: "merchandiseManagement",
        title: "商品管理",
        member: true,
        id: "05",
        subs: [{
                index: "management",
                title: "商品管理",
                memberRoot: true,
                id: "050",
            },
            {
                index: "appointmentManagement",
                title: "预约管理",
                memberRoot: true,
                id: "051",
            },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
        ],
    },
    {
        icon: "#icon-gift",
        index: "marketingActivities",
        title: "营销活动",
        member: true,
        id: "06",
        subs: [{
                index: "distribution",
                title: "分销",
                id: "060",
                memberRoot: true,
                subs: [{
                        id: "065",
                        index: "distributionStatistics",
                        title: "分销统计",
                        memberRoot: true,
                    },
                    {
                        id: "061",
                        index: "personnelApplication",
                        title: "人员申请",
                        memberRoot: true,
                    },
                    {
                        id: "062",
                        index: "distributionLevel",
                        title: "分销等级",
                        memberRoot: true,
                    },
                    {
                        id: "063",
                        index: "distributionOrder",
                        title: "分销订单",
                        memberRoot: true,
                    },
                    {
                        id: "064",
                        index: "accountTransfer",
                        title: "到账流水",
                        memberRoot: true,
                    },
                ],
            },
            {
                index: "joinGroup",
                title: "拼团",
                memberRoot: true,
                id: "07",
                subs: [{
                        id: "070",
                        index: "productList",
                        title: "拼团商品列表",
                        memberRoot: true,
                    },
                    {
                        id: "071",
                        index: "listParticipants",
                        title: "参团列表",
                        memberRoot: true,
                    },
                ],
            },
            {
                index: "spike",
                title: "秒杀",
                memberRoot: true,
                id: "08",
                subs: [{
                    id: "080",
                    index: "spikeList",
                    title: "秒杀商品列表",
                    memberRoot: true,
                }, ],
            },
            {
                index: "couponManagement",
                title: "优惠券管理",
                id: "09",
                memberRoot: true,
                subs: [{
                    id: "090",
                    index: "couponList",
                    title: "优惠券列表",
                    memberRoot: true,
                }, ],
            },
        ],
    },
    {
        icon: "#icon-mobile",
        index: "TurnToIntroduce",
        title: "转介绍管理",
        id: "10",
        member: true,
        subs: [{
                index: "partnerList",
                title: "合作伙伴列表",
                memberRoot: true,
                id: "100",
            },
            {
                index: "rulesSetting",
                title: "规则设置",
                memberRoot: true,
                id: "101",
            },
            {
                index: "withdrawalList",
                title: "提现列表",
                memberRoot: true,
                id: "102",
            },
        ],
    },
    {
        icon: "#icon-creditcard",
        index: "financialManagement",
        title: "财务管理",
        member: true,
        id: "11",
        subs: [{
                index: "financialStatistics",
                title: "财务统计",
                id: "110",
                memberRoot: true,
            },
            {
                index: "commissionManagement",
                title: "佣金管理",
                id: "111",
                memberRoot: true,
            },
            {
                index: "accountManagement",
                title: "账户管理",
                root: true,
                id: "112",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "#icon-cluster",
        index: "agentManage",
        title: "代理企业",
        member: true,
        isAgent: true,
        id: "12",
    },
    {
        icon: "#icon-solution",
        index: "articleManagement",
        title: "文章/活动报名管理",
        member: true,
        id: "13",
        subs: [{
                index: "customerArticles",
                title: "获客文章管理",
                memberRoot: true,
                subs: [{
                        index: "news",
                        title: "新闻资讯",
                        memberRoot: true,
                        id: "130"
                    },
                    {
                        index: "customerArticles",
                        title: "获客文章",
                        memberRoot: true,
                        id: "131"
                    },
                    {
                        index: "dynamicTable",
                        title: "动态管理",
                        memberRoot: true,
                        id: "134"
                    },
                    {
                        index: "employeeData",
                        title: "员工数据",
                        memberRoot: true,
                        id: "132"
                    },
                    {
                        index: "visitorData",
                        title: "访客数据",
                        memberRoot: true,
                        id: "133"
                    },
                ],
            },
            {
                index: "activityRegistration",
                title: "活动报名管理",
                memberRoot: true,
                id: "14",
            },
            {
                index: "dynamicBasicSetting",
                title: "基础设置",
                memberRoot: true,
                id: "15",
            },
        ],
    },
    {
        icon: "#icon-video",
        index: "videoManage",
        title: "短视频管理",
        member: true,
        id: "16",
    },
    {
        icon: "#icon-key",
        index: "addedServices",
        title: "增值服务",
        member: true,
        id: "17",
        subs: [{
                id: "170",
                index: "myNeeds",
                title: "我的需求",
                memberRoot: true,
            },
            {
                index: "demandCheck",
                title: "需求审核",
                root: true,
                administrator: true,
                memberRoot: true,
                id: "171"
            },
            {
                index: "demandContactsCheck",
                title: "人脉集市审核",
                root: true,
                administrator: true,
                memberRoot: true,
                id: "172"
            },
            {
                index: "demandProtuctsCheck",
                title: "产品中心审核",
                root: true,
                administrator: true,
                memberRoot: true,
                id: "173"
            },
            {
                index: "demandPublishSetting",
                title: "发布设置",
                memberRoot: true,
                administrator: true,
                root: true,
                id: "174"
            },
            {
                index: "demandReport",
                title: "举报处理",
                memberRoot: true,
                administrator: true,
                root: true,
                id: "175"
            },
            {
                index: "demandCustomize",
                title: "自定义设置",
                memberRoot: true,
                administrator: true,
                root: true,
                id: "176"
            },
        ],
    },
    {
        icon: "#icon-share",
        index: "sharingPromotion",
        title: "分享推广管理",
        member: true,
        id: "18",
        subs: [{
                id: "180",
                index: "posterAtlas",
                title: "海报图集",
                memberRoot: true,
            },
            {
                id: "181",
                index: "clockData",
                title: "打卡数据",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "#icon-api",
        index: "miniProgram",
        title: "小程序设置",
        id: "19",
        member: true,
        subs: [{
                index: "appletConfig",
                title: "基础设置",
                memberRoot: true,
                id: "190",
            },
            {
                index: "appletAd",
                title: "广告设置",
                memberRoot: true,
                id: "194",
            },
            {
                index: "appletPay",
                title: "支付设置",
                memberRoot: true,
                id: "191",
            },
            {
                index: "addCrmAppModel",
                title: "小程序装修",
                memberRoot: true,
                id: "0112",
            },
            {
                index: "basicSetting",
                title: "名片配置",
                root: true,
                memberRoot: true,
                id: "192",
            },
            {
                index: "mallSetting",
                title: "商城设置",
                memberRoot: true,
                id: "193",
            },
        ],
    },
];
//max 54
// 部分功能还没实现
let parkItems = [{
        icon: "#icon-team",
        index: "parkMember",
        title: "分销会员",
        id: "27",
        member: true,
        type: "parkMember",
        subs: [{
                id: "270",
                index: "parkMember",
                title: "会员管理",
                memberRoot: true,
            },
            {
                id: "271",
                index: "parkMemberLevel",
                title: "会员等级",
                memberRoot: true,
            },
            {
                id: "275",
                index: "parkMemberDistributor",
                title: "分销设置",
                memberRoot: true,
            },
            {
                id: "276",
                index: "parkMemberDistributorRecord",
                title: "分销记录",
                memberRoot: true,
            },
            {
                id: "274",
                index: "parkMemberOrder",
                title: "会员订单",
                memberRoot: true,
            },
            {
                id: "272",
                index: "parkIntegralRecord",
                title: "积分记录",
                memberRoot: true,
            },
            {
                id: "273",
                index: "parkMemberTask",
                title: "会员任务",
                memberRoot: true,
            },
        ],
    },
    {
        id: "48",
        icon: "#icon-team",
        index: "partnership",
        title: "互通管理",
        member: true,
        subs: [{
                id: "481",
                index: "parkCompanyGoodsLink",
                title: "商品互通",
                memberRoot: true,
            },
            {
                id: "483",
                index: "parkCompanyOrderGoodsLink",
                title: "预约商品",
                memberRoot: true,
            },
            {
                id: "482",
                index: "sharedLibrary",
                title: "共享库",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "el-icon-money",
        index: "parkWithdrawal",
        title: "提现管理",
        member: true,
        id: "43",
    },
    {
        icon: "el-icon-school",
        index: "parkManagement",
        title: "园区管理",
        member: true,
        type: "parkManagement",
        id: "20",
        subs: [{
                index: "parkManagement",
                title: "园区管理",
                memberRoot: true,
                id: "200",
            },
            {
                index: "parkServices",
                title: "园区动态",
                memberRoot: true,
                id: "201",
            },
            {
                index: "parkDynamicType",
                title: "动态分类",
                memberRoot: true,
                id: "206",
            },
            {
                index: "parkCompany",
                title: "园区企业",
                memberRoot: true,
                id: "202",
            },
            {
                index: "parkCard",
                title: "园区名片",
                memberRoot: true,
                id: "203",
            },
            // {
            //     index: "*",
            //     title: "园区党建",
            //     memberRoot: true,
            //     id: "205",
            // }
        ],
    },
    {
        icon: "#icon-file-text",
        index: "parkOrderList",
        title: "订单管理",
        member: true,
        id: "41"
    },
    {
        icon: "el-icon-brush",
        index: "parkActivity",
        title: "园区活动",
        member: true,
        id: "33",
        subs: [{
            id: "330",
            index: "parkActivity",
            title: "活动报名管理",
            memberRoot: true,
        }]
    },
    {
        icon: "el-icon-school",
        index: "parkIntoCompany",
        title: "进驻商家",
        member: true,
        type: "parkIntoCompany",
        id: "21",
        subs: [{
                index: "parkIntoCompany",
                title: "合作商家",
                memberRoot: true,
                id: "210",
            },
            {
                index: "applyParkIntoCompany",
                title: "进驻审核",
                memberRoot: true,
                id: "211",
            }
        ],
    },
    {
        icon: "#icon-shopping",
        index: "parkProduct",
        title: "商品管理",
        member: true,
        type: "parkProduct",
        id: "38",
        subs: [{
            index: "parkProduct",
            title: "公司商品",
            memberRoot: true,
            id: "380",
        }],
    },
    {
        // 部分功能还没实现
        icon: "el-icon-office-building",
        index: "propertyServices",
        title: "物业服务",
        member: true,
        id: "22",
        type: "propertyServices",
        subs: [{
                id: "220",
                index: "houseSpace",
                memberRoot: true,
                title: "房屋部面图",
            },
            {
                id: "228",
                index: "propertyThing",
                memberRoot: true,
                title: "物业报修",
            },
            // {
            //   index: "property",
            //   title: "物业管理",
            //   memberRoot: true,
            // },
            {
                id: "221",
                index: "propertyStyle",
                title: "物业风采",
                memberRoot: true,
            },
            {
                id: "222",
                index: "building",
                memberRoot: true,
                title: "楼宇管理",
            },
            {
                id: "223",
                index: "house",
                memberRoot: true,
                title: "房屋管理",
            },
            {
                id: "224",
                index: "rentalManagement",
                memberRoot: true,
                title: "出租管理",
            },
            {
                id: "225",
                index: "meeting",
                memberRoot: true,
                title: "会议室管理",
            },
            {
                id: "226",
                index: "household",
                memberRoot: true,
                title: "住户管理",
            },
            {
                id: "227",
                index: "qrCodeodeManagement",
                memberRoot: true,
                title: "二维码管理",
            },
        ],
    },
    {
        // 还没实现
        icon: "el-icon-brush",
        index: "repair",
        title: "园区报修",
        member: true,
        disabled: true,
        type: "repair",
        id: "23",
        subs: [{
                id: "230",
                index: "repairType",
                title: "服务分类",
                memberRoot: true,
            },
            {
                id: "231",
                index: "repairInfo",
                title: "信息管理",
                memberRoot: true,
            },
            // {
            //     id: "232",
            //     index: "repair",
            //     title: "手工报修",
            //     memberRoot: true,
            // },
            {
                id: "233",
                index: "repairCost",
                title: "维修费用",
                memberRoot: true,
            },
            {
                id: "234",
                index: "repairManage",
                title: "园区接收员",
                memberRoot: true,
            },
            {
                id: "235",
                index: "repairPManage",
                title: "分管接收员",
                memberRoot: true,
            },
        ],
    },
    {
        // 还没实现
        icon: "el-icon-set-up",
        index: "guard",
        title: "智能门禁",
        member: true,
        disabled: true,
        type: "guard",
        id: "24",
        subs: [{
                id: "240",
                index: "guardInfo",
                title: "基本设置",
                memberRoot: true,
            },
            {
                id: "241",
                index: "guardGrouping",
                title: "门禁分组",
                memberRoot: true,
            },
            {
                id: "242",
                index: "guard",
                title: "门禁管理",
                memberRoot: true,
            },
            {
                id: "243",
                index: "guardUser",
                title: "用户管理",
                memberRoot: true,
            },
            {
                id: "244",
                index: "guardFaceAuthorization",
                title: "人脸授权",
                memberRoot: true,
            },
            {
                id: "245",
                index: "guardFaceRecord",
                title: "人脸记录",
                memberRoot: true,
            },
            {
                id: "246",
                index: "guardSendCards",
                title: "发卡管理",
                memberRoot: true,
            },
            {
                id: "247",
                index: "guardCardSwipingRecord",
                title: "刷卡记录",
                memberRoot: true,
            },
            {
                id: "248",
                index: "guardDoorOpeningRecord",
                title: "开门记录",
                memberRoot: true,
            },
            {
                id: "249",
                index: "guardDoorOpeningStatistics",
                title: "开门统计",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "el-icon-set-up",
        index: "parks",
        title: "智能车禁",
        member: true,
        disabled: true,
        id: "25",
        type: "parks",
        subs: [{
                id: "250",
                index: "parksChargingStandard",
                title: "收费标准",
                memberRoot: true,
            },
            {
                id: "251",
                index: "parksParkingLotManagement",
                title: "车场管理",
                memberRoot: true,
            },
            {
                id: "252",
                index: "parksDeviceManagement",
                title: "设备管理",
                memberRoot: true,
            },
            {
                id: "253",
                index: "parksLaneManagement",
                title: "车道管理",
                memberRoot: true,
            },
            {
                id: "254",
                index: "parksPreferentialManagement",
                title: "优惠管理",
                memberRoot: true,
            },
            {
                id: "255",
                index: "parksParkingSpaceManagement",
                title: "车位管理",
                memberRoot: true,
            },
            {
                id: "256",
                index: "parksVehicleManagement",
                title: "车辆管理",
                memberRoot: true,
            },
            {
                id: "257",
                index: "parksDataRecord",
                title: "数据记录",
                memberRoot: true,
            },
            {
                id: "258",
                index: "parksFinancialCenter",
                title: "财务中心",
                memberRoot: true,
            },
        ],
    },
    {
        // 施工中
        icon: "el-icon-view",
        index: "securityPatrol",
        title: "安防巡更",
        member: true,
        disabled: true,
        id: "26",
        type: "securityPatrol",
        subs: [{
                id: "260",
                index: "securityPatrolPatrolDistribution",
                title: "巡更分布",
                memberRoot: true,
            },
            {
                id: "261",
                index: "securityPatrolPatrolRoute",
                title: "巡更路线",
                memberRoot: true,
            },
            {
                id: "262",
                index: "securityPatrolDeviceManagement",
                title: "设备管理",
                memberRoot: true,
            },
        ]
    },
    {
        icon: "#icon-api",
        index: "parkMiniProgram",
        title: "小程序设置",
        member: true,
        id: "39",
        type: "parkMiniProgram",
        subs: [{
            id: "390",
            index: "addParkAppModel",
            title: "小程序装修",
            memberRoot: true,
        }]
    },
    {
        icon: "el-icon-wind-power",
        index: "parkAdvertisement",
        title: "广告管理",
        member: true,
        id: "36",
        type: "parkAdvertisement",
        subs: [{
            id: "360",
            index: "parkAdvertisement",
            title: "广告管理",
            memberRoot: true,
        }]
    },
    { //不显示到菜单中
        icon: "el-icon-setting",
        index: "securityPatrol",
        title: "预约管理",
        member: false,
        hideen: true, //隐藏
        id: "28",
        type: "securityPatrol"
    },
];
//max 54
let associationItems = [{
        icon: "#icon-team",
        index: "memberCenter",
        title: "会员中心",
        member: true,
        id: "31",
        type: "memberCenter",
        subs: [{
                id: "310",
                index: "memberList",
                title: "会员列表",
                memberRoot: true,
            },
            {
                id: "313",
                index: "membershipApplication",
                title: "入团审核",
                memberRoot: true,
            },
            {
                id: "314",
                index: "*",
                title: "会员服务",
                memberRoot: true,
            },
            {
                id: "311",
                index: "memberAttribute",
                title: "会员属性",
                memberRoot: true,
            },
            {
                id: "312",
                index: "memberType",
                title: "类型/职务",
                memberRoot: true,
            },
            {
                id: "315",
                index: "memberIndustry",
                title: "会员行业",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "el-icon-user",
        index: "associationMember",
        title: "分销会员",
        id: "34",
        member: true,
        type: "associationMember",
        subs: [{
                id: "340",
                index: "associationMember",
                title: "会员管理",
                memberRoot: true,
            },
            {
                id: "341",
                index: "associationMemberLevel",
                title: "会员等级",
                memberRoot: true,
            },
            {
                id: "345",
                index: "associationMemberDistributor",
                title: "分销设置",
                memberRoot: true,
            },
            {
                id: "347",
                index: "associationRightsAndInterests",
                title: "会员权益",
                memberRoot: true,
            },
            {
                id: "346",
                index: "associationMemberDistributorRecord",
                title: "分销记录",
                memberRoot: true,
            },
            {
                id: "344",
                index: "associationMemberOrder",
                title: "会员订单",
                memberRoot: true,
            }
        ],
    },
    {
        icon: "el-icon-help",
        index: "associationIntegralRecord",
        title: "积分管理",
        id: "54",
        member: true,
        type: "associationIntegralRecord",
        subs: [{
                id: "342",
                index: "associationIntegralRecord",
                title: "积分记录",
                memberRoot: true,
            },
            {
                id: "343",
                index: "associationMemberTask",
                title: "积分任务",
                memberRoot: true,
            },
            {
                id: "348",
                index: "pointsProgram",
                title: "积分计划",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "#icon-shopping",
        index: "associationProducts",
        title: "店铺管理",
        member: true,
        id: "35",
        type: "associationProducts",
        subs: [{
                index: "associationCooperativeMerchants",
                title: "合作商家",
                memberRoot: true,
                id: "352",
            },
            {
                index: "associationEnterpriseServiceMerchants",
                title: "企服商家",
                memberRoot: true,
                id: "353",
            },
            {
                index: "applyAssociationIntoCompany",
                title: "商家审核",
                memberRoot: true,
                id: "354",
            },
            {
                index: "memberProduct",
                title: "会员套餐",
                memberRoot: true,
                id: "351",
            },
            {
                index: "associationProduct",
                title: "会员商品",
                memberRoot: true,
                id: "350",
            },
        ],
    },
    {
        id: "47",
        icon: "#icon-team",
        index: "partnership",
        title: "互通管理",
        member: true,
        subs: [{
                id: "471",
                index: "associationCompanyGoodsLink",
                title: "商品互通",
                memberRoot: true,
            },
            {
                id: "473",
                index: "associationCompanyOrderGoodsLink",
                title: "预约商品",
                memberRoot: true,
            },
            {
                id: "472",
                index: "sharedLibrary",
                title: "共享库",
                memberRoot: true,
            },
        ],
    },
    {
        icon: "el-icon-money",
        index: "associationWithdrawal",
        title: "提现管理",
        member: true,
        id: "44",
    },
    {
        icon: "el-icon-office-building",
        index: "associationAdmin",
        title: "新闻动态",
        member: true,
        id: "29",
        type: "associationAdmin",
        subs: [{
                index: "associationDynamic",
                title: "社团动态",
                memberRoot: true,
                id: "291",
            },
            {
                index: "associationDynamicType",
                title: "动态分类",
                memberRoot: true,
                id: "292",
            },
            // {
            //     index: "*",
            //     title: "社团党建",
            //     memberRoot: true,
            //     id: "293",
            // }
        ],
    },
    {
        icon: "#icon-file-text",
        index: "associationOrderList",
        title: "订单管理",
        member: true,
        disabled: true,
        id: "42"
    },
    {
        icon: "el-icon-user",
        index: "userCenter",
        title: "用户中心",
        member: true,
        id: "300",
        type: "userCenter",
        subs: [{
                id: "301",
                index: "userList",
                title: "用户列表",
                memberRoot: true,
            },
            {
                id: "302",
                index: "*",
                title: "行业动态",
                memberRoot: true,
            },
        ],

    },
    {
        icon: "el-icon-brush",
        index: "associationActivity",
        title: "社团活动",
        member: true,
        id: "32",
        type: "associationActivity",
        subs: [{
                id: "320",
                index: "associationActivity",
                title: "活动报名管理",
                memberRoot: true,
            },
            {
                id: "321",
                index: "associationSpaces",
                title: "共享空间",
                memberRoot: true,
            }
        ],

    },
    {
        icon: "#icon-api",
        index: "associationMiniProgram",
        title: "小程序设置",
        member: true,
        id: "40",
        type: "associationMiniProgram",
        subs: [{
            id: "400",
            index: "addAssociationAppModel",
            title: "小程序装修",
            memberRoot: true,
        }]
    },
    {
        icon: "el-icon-wind-power",
        index: "associationAdvertisement",
        title: "广告管理",
        member: true,
        id: "37",
        type: "associationAdvertisement",
        subs: [{
            id: "370",
            index: "associationAdvertisement",
            title: "广告管理",
            memberRoot: true,
        }]
    },
    {
        icon: "el-icon-setting",
        index: "setAssociation",
        title: "系统设置",
        member: true,
        id: "45",
        type: "setAssociation",
        subs: [{
                id: "290",
                index: "association",
                title: "社团管理",
                memberRoot: true,
            },
            {
                id: "296",
                index: "associationHighForm",
                title: "高级表单",
                memberRoot: true,
            },
            {
                id: "295",
                index: "associationWxArticleRelease",
                title: "公众号设置",
                memberRoot: true,
            }, {
                id: "294",
                index: "associationTermSetting",
                title: "条款设置",
                memberRoot: true,
            },
            {
                id: "450",
                index: "associationWalletIntercommunication",
                title: "钱包互通",
                memberRoot: true,
            }
        ]
    },
];

export default {
    crmItems,
    parkItems,
    associationItems
}